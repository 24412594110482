




































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins]
})
export default class topicAdd extends Vue<globalMixins> {
  @Getter('proj') proj: object[]
  @Getter('userInfoLists') userInfoLists: object
  @Getter('proDetail') proDetail: Object
  @Getter('otherProj') otherProj: object[]
  @Getter('copyInfo') copyInfo: object
  @Action('userProfile') userProfile: Function
  @Action('findUser') findUser: Function
  @Action('addUserToProject') addUserToProject: Function
  @Action('delUser') delUser: Function
  @Action('copyProject') copyProject: Function
  @Action('projectDetail') projectDetail: Function
  @Action('updateProjectTitle') updateProjectTitle: Function
  @Action('delProject') delProject: Function

  editFromVisible: boolean = false
  addFromVisible: boolean = false
  userTags: any[] = []
  tagVisible: boolean = true
  newCreater: string = ''
  addTopicform: object = {
    name: null,
    description: null
  }
  topicTitleForm: object = {
    name: ''
  }
  topicTitleShow: boolean = false
  createTopicLoading: boolean = false
  projectId: Number = null
  noProj: boolean = true
  selectedLoading: boolean = false
  updateTopicLoading: boolean = false
  account: any =  JSON.parse(localStorage.getItem("account"))
  allProjects: object[] = []
  topicLists: any[] = []
  users: any[] = []
  newId: any = null
  topicName: string = '课题名称'
  rules: object = {
    name: [
      { required: true, message: '课题名称必填！', trigger: 'blur' }
    ],
    description: [
      { required: true, message: '课题描述必填', trigger: 'blur' }
    ]
  }
  formLabelWidth: string = '120px'
  userLists: any[] = []

  created () {
    this.getProjectsLists()
  }
  get allProjectsable () {
    /*
    * 有staff权限is_wheel = true, 可以查看
    * 有staff权限is_wheel = false, 可以查看
    * 没有staff权限is_wheel = true & false, 不可以查看全部
    */
    if (this.account.is_staff && this.account.is_wheel) {
      return true
    } else if (this.account.is_staff && !this.account.is_wheel) {
      return true
    } else if (!this.account.is_staff && this.account.is_wheel) {
      return true
    } else if (!this.account.is_staff && !this.account.is_wheel) {
      return false
    }
  }
  get delTopicabled () {
    /*
    * 有staff权限is_wheel= true, 可以删除
    * 有staff权限is_wheel=false, 可以删除
    * 没有staff权限is_wheel=false, 可以删除
    * 没有staff权限is_wheel=true, 不能删除
    */
    if (this.account.is_staff && this.account.is_wheel) {
      return true
    } else if (this.account.is_staff && !this.account.is_wheel) {
      return true
    } else if (!this.account.is_staff && !this.account.is_wheel) {
      return true
    } else if (!this.account.is_staff && this.account.is_wheel) {
      return false
    }
  }
  topicNameEdit (item) {
    this.projectId = item.id
    this.topicTitleForm = {
      name: item.name
    }
    this.addTopicform = {
      description: item.description,
      isSample: item.is_sample
    }
    this.topicTitleShow = true
  }
  // 判断是否为课题创建人
  isProjectCreator (leaders): boolean {
    let leaderList = leaders.map(item => {
      return item.id
    })
    let userId: any = (this.account as any).id
    return leaderList.includes(userId)
  }
  handleTopicTitleEdit () {
    let topicTitleForm = this.$refs.topicTitleForm as HTMLFormElement
    topicTitleForm.validate((valid) => {
      if (valid) {
        this.updateTopicLoading = true
        let params = {
          projectId: this.projectId,
          name: (this.topicTitleForm as any).name,
          description: (this.addTopicform as any).description,
          is_sample: (this.addTopicform as any).isSample
        }
        this.updateProjectTitle({ params }).then(() => {
          this.updateTopicLoading = false
          this.topicTitleShow = false
          this.getProjectsLists()
          this.successMsg('修改课题名称成功！')
        }).catch((err) => {
          this.updateTopicLoading = false
          this.topicTitleShow = false
          this.getProjectsLists()
          this.errorMsg(err.error.message)
        })
      }
    })
  }
  getProjectsLists () {
    this.userProfile().then(() => {
      if (!this.proj && !this.otherProj) {
        // 未加入任何课题
        this.noProj = false
      } else if (this.proj.length > 0 || this.otherProj.length > 0) {
        this.noProj = true
        this.allProjects = [...this.transformList(this.proj, false), ... this.transformList(this.otherProj, true)]
        let obj = this.getProjectInfo(this.allProjects)
        this.userTags = obj ? obj.leaders : []
      }
    }).catch((err) => {
      this.errorMsg(err.error.message)
      this.$router.push('/')
    })
  }
  handleUserSelect () {
    // 匹配空格之后的电话号码
    let phone = this.newCreater.split(' ').pop()
    let currentInfo = (this.userInfoLists as any).find((item, i) => {
      return phone === item.phone
    })
    let params = {
      projectId: this.projectId,
      userId: currentInfo.id
    }
    this.addToProject(params)
  }
  // 添加用户到当前项目
  addToProject (params) {
    this.addUserToProject({ params }).then(() => {
      this.successMsg('添加课题创建人成功！')
      this.tagVisible = !this.tagVisible
      this.getProjectsLists()
    }).catch((err) => {
      this.errorMsg(err.error.message)
      this.tagVisible = !this.tagVisible
      this.getProjectsLists()
    })
  }
  // 手机号 | 姓名远程搜索用户
  remoteMethod(query) {
    let params = {
      key: query
    }
    this.findUser({ params }).catch((err) => {
      this.errorMsg(err)
    })
  }
  transformList (data, flag) {
    let newProj = []
    data.map((item) => {
      newProj.push({
        isDisabled: flag,
        ...item
      })
    })
    return newProj
  }
  leadersLists (list) {
    let arr = []
    list.map(item => {
      arr.push(item.name)
    })
    return list.length > 0 ? arr.join(',') : '暂无'
  }
  handleEdit (item) {
    this.projectId = item.id
    this.editFromVisible = true
    this.tagVisible = false
    let obj = this.getProjectInfo(this.allProjects)
    this.userTags = obj ? obj.leaders : []
    // 课题名称
    this.topicName = item.name
  }
  // 查找当前的对应project info
  getProjectInfo (lists) {
    return lists.find(item => {
      return item.id === this.projectId
    })
  }
  handleNewAdd (id) {
    this.addFromVisible = true
    this.projectId = id
    this.addTopicform = {
      name: '',
      description: ''
    }
    // this.$nextTick(() => {
    //   let copyTopicForm = this.$refs.copyTopic as HTMLFormElement
    //   copyTopicForm.resetFields()
    // })
  }
  handleClose(tag) {
    let params = {
      projectId: this.projectId,
      userId: tag.id 
    }
    this.delUser({ params })
    .then(() => {
      this.getProjectsLists()
      this.successMsg('删除创建人成功！')
    })
    .catch((err) => {
      this.errorMsg(err.error.message)
    })
  }
  topicClick (id) {
    let project = this.allProjects.find((item: any) => {
      return item.id === id
    })
    localStorage.removeItem('projects')
    localStorage.setItem('projects', JSON.stringify(project))
    this.$router.push('/')
  }
  delTopic (id) {
    this.$confirm('此操作将永久删除本课题, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let params = {
        projectId: id
      }
      this.delProject({ params })
        .then(() => {
          this.getProjectsLists()
          this.successMsg('删除课题成功！')
        })
        .catch((err) => {
          this.errorMsg(err.error.message)
        })
    })
  }
  createAndEntry () {
    let copyTopicForm = this.$refs.copyTopic as HTMLFormElement
    copyTopicForm.validate((valid) => {
      if (!valid) {
        return
      }     
      this.createTopicLoading = true
      let params = {
        projectId: this.projectId,
        ...this.addTopicform
      }
      this.copyProject({ params }).then(() => {
        this.userProfile().then(() => {
          this.allProjects = [...this.transformList(this.proj, false), ... this.transformList(this.otherProj, true)]
          let obj = this.getProjectInfo(this.allProjects)
          this.userTags = obj ? obj.leaders : []
          this.newId = (this.copyInfo as any).id
          let project = this.allProjects.find((item: any) => {
            return item.id === Number(this.newId)
          })
          localStorage.setItem('projects', JSON.stringify(project))
          this.createTopicLoading = false
          this.$router.push('/topic-config')
        }).catch((err) => {
          this.createTopicLoading = false
          this.$router.push('/')
          this.errorMsg(err.error.message)
        })
      }).catch((err) => {
        this.createTopicLoading = false
        this.$router.push('/topic-add')
        this.errorMsg(err.error.message)
      })
    })
  }
  showInput() {
    this.newCreater = ''
    this.tagVisible = true
    this.userLists = []
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).focus()
    })
  }
  @Watch ('userInfoLists')
  onUserInfoListsChange (val: any, old: any) {
    this.userLists = val
  }
}
